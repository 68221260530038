import React, { FC } from 'react';
import { Container, Row, Col, Layout } from 'layout';

import headerProps from './header-mock.json';

import { LdsPageProps } from './models';
import 'styles/main.scss';

const LdsPage: FC<LdsPageProps> = ({ pageContext }) => {
  const { body } = pageContext;

  return (
    <Layout {...headerProps}>
      <section className="lds-page">
        {/* {titleParsed} */}
        <Container fluid>
          <Row>
            <Col lg="8">
              <div dangerouslySetInnerHTML={{ __html: body.replace(/(<p>&nbsp;<\/p>)+/g, '') }} />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default LdsPage;
